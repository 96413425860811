.header {
  margin-top: 30px;
  margin-bottom: 30px;
  direction: ltr;
}
.header .container {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 0px auto;
}
.header .container .logo {
  font-size: 22px;
}
.header .container .logo span {
  color: var(--main-color);
}
.header .container .nav-icon {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 30px;
}
.burger-icon {
  font-size: 32px;
  cursor: pointer;
}
@media (min-width: 767px) {
  .burger-icon {
    display: none;
  }
}
.header .container {
  position: relative;
}
@media (max-width: 767px) {
  .header .container nav ul {
    display: none !important;
  }
  .burger-icon {
    display: block;
  }
  .header .container nav ul {
    position: absolute;
    z-index: 100;
    left: 0;
    top: 58px;
    width: 100%;
    flex-direction: column;
    background: var(--main-color);
    padding: 32px;
  }
}
.header .container nav ul {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 30px;
}
.header .container nav ul li a {
  color: #303030;
  padding: 8px 10px;
  font-size: 22px;
  transition: 0.3s;
}
.header .container nav ul li {
  position: relative;
}
.header .container nav ul li::after {
  content: "";
  position: absolute;
  height: 4px;
  width: 0;
  background-color: var(--main-color);
  left: 0;
  bottom: 0;
  animation: 0.3s;
}
.header .container nav ul li:hover .header .container nav ul li::after {
  width: 100%;
}
.header .container nav ul li a:hover {
  color: #0295a9;
}
.header .container .icons {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 20px;
}
.header .container .icons a {
  display: contents;
}
.header .container .icons svg {
  color: black;
  cursor: pointer;
  font-size: 24px;
}
/* End Header  */
