.ads {
  padding: 20px;
  background-color: rgb(0, 146, 146);
}
.ads .container {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
}
.ads .box {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column-reverse;
  user-select: none;
}
.ads .box h4 {
  font-size: 35px;
  color: var(--bg-color);
  text-align: center;
}
.ads .box img {
  width: 120px;
}
.ads .holder {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(200px, 1fr));
}
