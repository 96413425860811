.footer {
  margin-top: 50px;
}
.footer .container {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(200px, 1fr));
  grid-gap: 40px;
  background-color: #006977;
}
.footer .container .box1 {
  padding: 20px;
}
@media (max-width: 767px) {
  .footer .container .box1 {
    border-bottom: 1px solid #0000002b;
  }
}
.footer .container .box1 .logo {
  font-size: 32px;
  color: var(--white-color);
  margin-bottom: 20px;
  text-align: center;
}
.footer .container .box1 .logo span {
  color: var(--main-color);
}
.footer .container .box1 .icon {
  margin: 10px auto;
  text-align: center;
  gap: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}
.footer .container .box1 svg {
  color: var(--white-color);
  font-size: 28px;
}
.footer .container .box2 {
  padding: 20px;
}
@media (max-width: 767px) {
  .footer .container .box2 {
    text-align: center;
  }
  .footer .container .box2:not(:last-child) {
    border-bottom: 1px solid #0000002b;
  }
}
.footer .container .box2 h2 {
  color: var(--white-color);
  font-size: 22px;
  font-weight: bold;
}
.footer .container .box2 a {
  color: var(--white-color);
  display: block;
  margin-top: 20px;
  cursor: pointer;
}
.footer .container .box2 .mail {
  font-size: 14px;
}
