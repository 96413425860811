.landing .content {
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: var(--bg-color);
  padding: 50px;
  max-width: 100%;
  gap: 20px;
}
.landing .container {
  display: flex;
  align-items: center;
  border-radius: 10px;
  justify-content: space-between;
}
@media (max-width: 767px) {
  .landing .content {
    flex-direction: column;
    text-align: start;
    align-items: baseline;
  }
}

.landing .container .holder {
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  flex-direction: column;
}
.landing .container h2 {
  margin-bottom: 10px;
  font-size: 32px;
}
.landing .container .more {
  color: #777;
  padding: 10px 30px;
  border-radius: 6px;
  background-color: var(--white-color);
  cursor: pointer;
  user-select: none;
}
.landing .container .icon {
  display: flex;
  align-items: center;
  margin-top: 10px;
  gap: 20px;
}
.landing .container .icon a {
  cursor: pointer;
}
.landing .container .icon svg {
  font-weight: 1200;
  margin-right: 20px;
  margin: 0 auto;
  font-size: 28px;
}
.landing .container .imgs {
  display: flex;
  align-items: center;
  gap: 20px;
}
@media (max-width: 767px) {
  .landing .container .imgs {
    flex-direction: column;
    width: fit-content;
    margin: 0 auto;
  }
}
.landing .container .imgs img {
  max-width: 100%;
  width: 260px;
  border-radius: 20px;
  box-shadow: 0px 12px 20px 0px rgb(0 0 0 / 13%),
    0px 2px 4px 0px rgb(0 0 0 / 12%);
}
@media (max-width: 1200px) {
  .landing .container .imgs img {
    height: 160px;
    width: 160px;
  }
}
@media (max-width: 767px) {
  .landing .container .imgs img {
    margin-top: 10px;
    height: 200px;
    width: 200px;
  }
}
#red {
  background-color: #ee6c4d;
}
#blue {
  background-color: #12adc1;
}
#yellow {
  background-color: #fdd037;
}
#green {
  background-color: #10a902;
}
