.product {
  margin-top: 50px;
  margin-bottom: 50px;
}
.product .container {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
  grid-gap: 40px;
}
.product .container .box {
  border-radius: 6px;
  padding: 20px;
  background-color: var(--white-color);
  box-shadow: 0px 12px 20px 0px rgb(0 0 0 / 8%), 0px 2px 4px 0px rgb(0 0 0 / 9%);
  transition: 0.3s;
  border: 1px solid #d7d7d7;
}
.product .container .box:hover {
  box-shadow: 0px 12px 20px 0px rgb(0 0 0 / 20%),
    0px 2px 4px 0px rgb(0 0 0 / 22%);
  transform: translateY(-10px);
}

.product .container .box img {
  height: 300px;
  width: 300px;
  border-radius: 6px;
}
.product .container .box .text h2 {
  margin-top: 10px;
  color: var(--main-color);
}
.product .container .box .text p {
  line-height: 1.8;
  margin-top: 10px;
  color: #777;
  font-size: 18px;
}
.product .container .box .btns {
  display: flex;

  align-items: center;
  justify-content: space-between;
  margin-top: 10px;
}
.product .container .box .btns .price {
  color: #000;
  font-size: 22px;
  font-weight: bold;
  padding: 5px 15px;
  position: relative;
}
.product .container .box .btns .price::before {
  content: "Dh";
  font-size: 15px;
  position: absolute;
  top: 0;
  right: -8px;
}
.product .container .box .btns .buy-btn {
  font-size: 22px;
  font-weight: bold;
  padding: 8px 10px;
  cursor: pointer;
  border-radius: 50px;
  transition: 0.3s;
  color: var(--main-color);
  background-color: var(--white-color);
}
.product .container .box:hover .btns .buy-btn {
  color: #fff;
  background-color: var(--alt-color);
}
