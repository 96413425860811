.latest {
  margin-top: 50px;
  margin-bottom: 50px;
}
.latest .container {
  display: flex;
  align-items: center;
  justify-content: space-between;
  box-shadow: 2px 2px 20px 2px #4f49495e;
  padding: 20px 50px;
}

@media (max-width: 1200px) {
  .latest .container {
    padding: 50px 50px;
  }
}
@media (max-width: 767px) {
  .latest .container {
    flex-direction: column;
    padding: 34px;
  }
}
.latest .container .text h2 {
  color: #000;
  font-size: 50px;
}
.latest .container .text p {
  color: #777;
  font-size: 35px;
  margin-top: 5px;
  margin-bottom: 30px;
}
.latest .container .text a {
  color: var(--dark-color);
  padding: 10px 15px;
  background-color: var(--main-color);
  border-radius: 6px;
  font-size: 18px;
  transition: 0.3s;
}
.latest .container .text a:hover {
  color: var(--white-color);
  background-color: var(--alt-color);
}
.latest .container .image img {
  width: 400px;
}
@media (max-width: 1200px) {
  .latest .container .image img {
    height: 300px;
    width: 300px;
    margin-top: 30px;
  }
}
