@import url("https://fonts.googleapis.com/css2?family=IBM+Plex+Mono:wght@100;200;300;400;500;600;700&family=Ubuntu:ital,wght@0,300;0,400;0,500;0,700;1,300;1,400;1,500;1,700&display=swap");

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: "arial";
}
:root {
  --main-color: #98c1d9;
  --alt-color: #3d5a80;
  --bg-color: #ee6c4d;
  --white-color: #e0fbfc;
  --dark-color: #293241;
}

a {
  text-decoration: none;
}
ul {
  list-style: none;
  padding: 0;
}
h1,
h2,
h3,
h4,
h5,
h6 {
  text-transform: capitalize;
}
body {
  background-color: #eeeeee;
  scroll-behavior: smooth;
  font-family: "Ubuntu";
  direction: rtl;
}
html {
  scroll-behavior: smooth;
}
/*Start web-size===========*/
.container {
  padding-left: 15px;
  padding-right: 15px;
  margin-left: auto;
  margin-right: auto;
  margin: auto;
}
/*SMAll===============*/
@media (min-width: 768px) {
  .container {
    width: 750px;
  }
}
/*MEDIUM============*/
@media (min-width: 992) {
  .container {
    width: 970px;
  }
}
/*LARGE=============*/
@media (min-width: 1200px) {
  .container {
    width: 1170px;
  }
}
svg {
  color: #000;
}
img {
  user-select: none;
}
/* .main--page {
  display: none;
} */
input[type="submit"],
button {
  box-shadow: 0px 2px 20px 3px var(--bg-color);
}
