.products .holder {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  gap: 30px;
}
@media (max-width: 1200px) {
  .products .holder {
    flex-direction: column;
  }
}
.products .gallery {
  margin: 0 auto;
}

.products .gallery .main--vue img {
  transition: imageShow;
  transition-duration: 0.5s;
  transition-property: all;
  border-radius: 6px;
  opacity: 100;
  width: 400px;
}
@media (max-width: 767px) {
  .products .gallery .main--vue img {
    width: 300px;
  }
}
@keyframes imageShow {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 100;
  }
}
.products .gallery .imgs {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 10px;
  gap: 10px;
}
.products .gallery .imgs img {
  width: 100px;
  border-radius: 6px;
  cursor: pointer;
}

@media (max-width: 1200px) {
  .products .product--details {
    padding: 30px 0;
  }
}
.products .product--details h1 {
  font-size: 50px;
  color: var(--dark-color);
}
.products .product--details div {
  margin: 20px 0;
  line-height: 2.2;
  color: #777;
  font-size: 18px;
}
.products .product--details div li {
  list-style: square;
}

.products .product--details div li span {
  color: #000;
  font-size: 25px;
}
.products .product--details .price {
  font-size: 47px;
  color: var(--alt-color);
  position: relative;
  width: fit-content;
  font-weight: 800;
}
.products .product--details .price span {
  font-size: 20px;
  position: absolute;
  top: 0;
  right: -15px;
}
.products .order {
  padding: 20px 0;
}
.products .order h2 {
  color: var(--dark-color);
  font-size: 32px;
}
.products .order form {
  display: flex;
  flex-direction: column;
  gap: 15px;
}
.products .order form label {
  font-size: 26px;
  text-transform: capitalize;
}
.products .order form textarea,
.products .order form input {
  width: 100%;
  height: 44px;
  border: 1px solid var(--bg-color);
  border-radius: 8px;
  font-size: 22px;
  padding: 10px;
  resize: vertical;
}
.products .order form textarea {
  height: 100px;
}
.products .order form textarea:focus,
.products .order form input:focus {
  outline: 0;
}

.products .order form .submit {
  height: 44px;
  font-size: 21px;
  font-weight: 600;
  border-radius: 8px;
  width: 40%;
  align-self: end;
  background-color: var(--alt-color);
  color: var(--white-color);
  cursor: pointer;
}
.products .order .order--done {
  text-align: center;
  padding: 30px 0;
}
.products .order .order--done h2 {
  color: green !important;
}
.products .order .order--done p {
  color: #777;
  font-size: 32px;
}
.order .Product--title {
  border: 0 !important;
  outline: 0;
  user-select: none;
}
